import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { Alert, Button, Container, Form,  Modal } from 'react-bootstrap'
import Layout from "../components/layout"
import queryString from 'query-string'
import axios from 'axios'

export default ({location}) => {

    const [state, setState] = useState({
        dirty: false,
        validated: false,
        error_msg: '',
        info_msg: '',
        workshop_id: queryString.parse(location.search).id,
        email: ''
    });

    function handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({...state,
            dirty: true,
            [name]: value
        })
    }

    function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setState({...state, validated: true})
            return
        }
      
        setState({...state, validated: true, dirty: false, error_msg: ''});
        
        axios({method: 'post', url:'/.netlify/functions/workshopCancel', data: state}).then(response => {
            setState({...state, info_msg: 'Votre réservation est maintenant annulée !'})
        }).catch((err) => {
            console.log(err.response)
            if (err.response) {
                if (err.response.status === 500) {
                    alert("Une erreur technique est survenue, veuillez contacter notre équipe afin qu'on puisse résoudre le problème dans les meilleurs délais")
                } else if (err.response.status === 400) {
                    setState({...state, error_msg: err.response.data.msg})
                }
            }
        })
    }

    return (
        <Layout>
            <Container className="text-center">
            <Modal show={true} onHide={() => navigate('/')}>
                <Modal.Header closeButton>
                    <Modal.Title>Annulation de réservation d'atelier</Modal.Title>
                </Modal.Header>
                <Form
                    validated={state.validated} 
                    onSubmit={handleSubmit}
                    className="border rounded px-4 needs-validation" noValidate>

                    <Form.Label className="my-2 mx-2">Veuillez confirmer votre adresse email</Form.Label>
                    <Form.Control
                        name="email"
                        onChange={handleChange}
                        value={state.email}
                        type="email"
                        pattern=".+@.+\..+"
                        required
                    />
                    <Button 
                            type="submit"
                            className={"btn btn-green my-4 " + ((state.error_msg || state.info_msg) ? 'd-none' :'')}>
                        Annuler ma réservation
                    </Button>
                </Form>
                <Alert variant="danger" className={state.error_msg ? '': 'd-none'}>{state.error_msg}</Alert>
                <Alert variant="green" className={state.info_msg ? '': 'd-none'}>{state.info_msg}</Alert>
                
            </Modal>
            </Container>
        </Layout>
    )
}